import useValidator from "../../lib/validator";
import useRegex from "../../vars/regex";

export default function useValidation(s, state, error, setError) {
    const getValidationError = useValidator()
    const {email} = useRegex()


    const schema =  {
        email: [ v => email.test(v) ? null : 'error', {
            error: s('email-error')
        }],
        password: [ v => {
            if (v.length === 0) return 'empty'
            return null
        }, {
            empty: s('password-error-empty')
        }]
    }

    const validate = (key, value) => setError({
        ...error,
        [key]: getValidationError(key, value, schema)
    })

    const validateAll = () => {
        let errors = {}
        for(let key in state) {
            errors[key] = getValidationError(key, state[key], schema)
        }

        setError(errors)
    }

    return {validate, validateAll }
}