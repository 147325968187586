import React from 'react'
import clsx from "clsx";
import css from './TextField.module.css'
import Text from "../text";

export default function TextField({
    className, label = null, helperText = null, locale='en', hint = '', value = '',
    onClick= _ => {},
    onChange, onKeyDown, onKeyUp, onFocus, onBlur,
    onRightIconClick = _ => {},
    onLeftIconClick = _ => {},
    readOnly = false,
    rightIcons = [],
    leftIcons = [],
    inputType = 'text',
    error = false,
    errorText =  '',
    required = false,
    inputName
}) {
    const [rightIconIdx, setRightIconIdx] = React.useState(0)
    const [leftIconIdx, setLeftIconIdx] = React.useState(0)

    const RightIcon = !!rightIcons[rightIconIdx] ? rightIcons[rightIconIdx] : null
    const LeftIcon = !!leftIcons[leftIconIdx] ? leftIcons[leftIconIdx] : null

    const classes = clsx(
        css.textFieldWithActions,
        {
            [css.spacingTop]: !!label,
            [css.spacingBottom]: !!helperText || !!errorText || error,
            [css.textFieldFilled]: !!value,
            [css.hasError]: !!errorText || error,
        }
    )

    const inputClasses = clsx(
        css.textField,
        {
            [css.readOnlyTextField]: readOnly
        }
    )

    return (
        <div className={clsx(css.textFieldRoot, className)}>
            <Text variant='bold' locale={locale}>{label}{required ? ' *' : ''}</Text>

            <div className={classes}>
                <div className={css.rightIcon} onClick={_ => onLeftIconClick(leftIconIdx, setLeftIconIdx)}>
                    { LeftIcon }
                </div>

                <input className={inputClasses}
                       placeholder={hint}
                       value={value}
                       onClick={onClick}
                       onChange={onChange}
                       onKeyDown={onKeyDown}
                       onKeyUp={onKeyUp}
                       type={inputType}
                       readOnly={readOnly}
                       onFocus={onFocus}
                       onBlur={onBlur}
                       name={inputName}
                />

                <div className={css.rightIcon} onClick={_ => onRightIconClick(rightIconIdx, setRightIconIdx)}>
                    { RightIcon }
                </div>
            </div>

            {
                !!errorText ?
                    <Text variant='small' locale={locale} color='redA700'>{errorText}</Text> :
                    <Text variant='small' locale={locale} color='grey600'>{helperText}</Text>
            }
        </div>
    )
}
