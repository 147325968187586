import withSession, {withSessionForPage} from "../../lib/session";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import css from './login.module.css'
import {useTranslation} from "next-i18next";
import useImages from "../../vars/images";
import TextField from "../../lib/components/text-field";
import useInputs from "../login/inputs";
import {Icon} from "../../lib/components/icon";
import React, {useState} from "react";
import useLinks from "../../vars/links";
import Button from "../../lib/components/button";
import {usePageContext} from "../../lib/page-context";
import Text from "../../lib/components/text";
import Head from "next/head";
import {withAPI} from "../../data";
import userInternalLogin from "../../data/apis/internal-login";

function LogIn() {
    const [{locale,apiCalls}] = usePageContext()
    const {t} = useTranslation(['signup-login', 'navbar'])
    const [serverError, setServerError] = useState('')
    const images = useImages()
    const Links = useLinks()

    const {
        inputState, error,  texts,
        generateNormalTextField, onInputChange,
        hasError, validate, validateAll
    } = useInputs(locale, t)

    const [passwordFieldType, setPasswordFieldType] = useState('password')

    const loginApi = userInternalLogin( setServerError, t)

    const sendLogInRequest = async () => {
        console.log("clicked")
        if(validateAll()) return
          console.log("clicked")
         await loginApi({
            ...inputState,
            locale
        })
    }

    const togglePasswordVisibility = (key, set) => {
        let newPasswordFileType = passwordFieldType === 'password' ? 'text' : 'password'
        let newKey = passwordFieldType === 'password' ? 1 : 0

        set(newKey)
        setPasswordFieldType(newPasswordFileType)
    }

    return (
            <div className={css.row}>
                <Head>
                    <title>{t('navbar:login')}</title>
                    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                </Head>

                <Head>
                    <title>{t('navbar:login')}</title>
                    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                </Head>
                <div className={css.form}>
                    <img src={images.loginSignupLogo} alt={t('navbar:login')}/>
                    { generateNormalTextField('email') }

                    <TextField
                        locale={locale}
                        rightIcons={[
                            <Icon variant='eye-open' color='grey700'/>,
                            <Icon variant='eye-closed' color='grey700'/>
                        ]}
                        onRightIconClick={togglePasswordVisibility}
                        onChange={e => onInputChange(e, 'password')}

                        value={inputState.password}
                        hint={texts.password}
                        inputType={passwordFieldType} />

                    <Button
                        tabindex="-1"
                        onClick={sendLogInRequest}
                        locale={locale}
                        disabled={hasError()|| apiCalls.length !== 0}
                        size='l'
                        variant='primary' >{
                        apiCalls.length > 0 ?
                            t('common:loading') :
                            t('login')
                    }</Button>

                    <Text className={css.align} variant='body' href={Links.forgotPassword} color={'blue'} locale={locale}>{t('forgotPassword')}</Text>
                    <Text locale={locale} variant='body' color='redA700'>{serverError}</Text>


                </div>

            </div>
    )
}

export default withAPI(LogIn)

export const getServerSideProps = withSessionForPage({
    localeNS: ['signup-login'],
    serverSideTranslations,
    reverseAuth: true
})