import {usePageContext} from "../../lib/page-context";
import useAPIErrorCodes from "../../vars/api-errors";
import {useRouter} from "next/router";
import useLinks from "../../vars/links";
import {useNewAPICall} from "../index";

export default function useInternalLogIn( setServerError, t) {
    const [ctx] = usePageContext()
    const errorCodes = useAPIErrorCodes()
    const links = useLinks()
    const newAPICall = useNewAPICall()
    const client = ctx.httpClients.internal
    const router = useRouter()

    return async function ({ email, password}) {
        let remove = newAPICall()

        let [_, error] = await client({
            url: '/api/login',
            data: {
                email, password
            },
            method: 'POST'
        })

        if (!error) {
            remove(0)
            await router.push(links.home)

            return
        }
            setServerError( t(errorCodes.PasswordInvalid))

        remove(0)
    }
}