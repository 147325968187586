import React, { useState } from 'react'
import TextField from "../../lib/components/text-field";
import useValidation from "./validation";

export default function useInputs(locale, s) {
    const [state, setState] = useState({
        email: '',
        password: '',
    })

    const [error, setError] = useState({
        email: '',
        password: '',
    })

    const texts = {
        email: s('email'),
        password: s('password')
    }

    const { validateAll, validate } = useValidation(s, state, error, setError)
    const { onInputChange, generateNormalTextField } = useTextFieldGenerator(
        [state, setState],
        error, texts, locale,
        validate
    )

    return {
        inputState: state,
        error,
        onInputChange,
        generateNormalTextField,
        texts,

        validate, validateAll,

        hasError: () => {
            for (let key in error) {
                if (!!error[key]) return true
            }

            return false
        }
    }
}

function useTextFieldGenerator([state, setState], error, texts, locale, validate) {
    const generateNormalTextField = (key) => (
        <TextField
            name={key}
            error={!!error[key]}
            errorText={error[key]}
            onBlur={() => {
                validate(key, state[key])
            }}
            locale={locale}
            hint={texts[key]}
            value={state[key]}
            onChange={e => onInputChange(e, key)} />
    )

    const onInputChange = (e, key) => {
        validate(key, e.target.value)

        setState({
            ...state,
            [key]: e.target.value
        })
    }

    return { generateNormalTextField, onInputChange }
}